<template>
    <div
        class="modal fade"
        :id="modalName"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalScrollableTitle"
        aria-hidden="true"
    >
        <div
            class="modal-dialog modal-dialog-centered modal-dialog-scrollable"
            role="document"
        >
            <div class="modal-content">
                <div class="modal-header">
                    <h5
                        class="modal-title"
                        id="exampleModalScrollableTitle"
                    ></h5>
                    <button
                        type="button"
                        class="close"
                        data-dismiss="modal"
                        aria-label="Close"
                        @click="close()"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div
                        class="d-flex align-items-center"
                        style="flex-direction: column; margin-top: 3rem;"
                    >
                        <form autocomplete="off">
                            <div class="modal-body">
                                <div class="form-group row">
                                    <label class="col-lg-4 col-form-label">
                                        Nomor Induk Kependudukan :<span
                                            style="color:red;"
                                            >*</span
                                        ></label
                                    >
                                    <div class="col-lg-6">
                                        <input
                                            maxlength="16"
                                            :class="{
                                                'is-invalid': errors.has('ssn')
                                            }"
                                            type="number"
                                            class="form-control"
                                            placeholder="NIK KTP Pasien"
                                            v-validate="'required'"
                                            data-vv-as="ssn"
                                            data-vv-scope="form"
                                            name="ssn"
                                            v-model="dataPasien.nik"
                                            @keyup="inputSsn"
                                            @paste="inputSsn"
                                        />
                                        <div
                                            v-if="!isNikValid"
                                            style="display: inline;"
                                            class="invalid-feedback"
                                        >
                                            NIK tidak valid
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-lg-4 col-form-label"
                                        >{{ $t("common.firstName") }}:<span
                                            style="color:red;"
                                            >*</span
                                        ></label
                                    >
                                    <div class="col-lg-6">
                                        <input
                                            type="text"
                                            class="form-control"
                                            placeholder="Nama Depan Pasien"
                                            v-validate="'required'"
                                            data-vv-as="Nama Depan Pasien"
                                            name="patientFirstName"
                                            v-model="dataPasien.namaDepan"
                                        />
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-lg-4 col-form-label"
                                        >{{ $t("common.lastName") }}:
                                        <!-- <span style="color:red;"
                                            >*</span> -->
                                    </label>
                                    <div class="col-lg-6">
                                        <input
                                            type="text"
                                            class="form-control"
                                            placeholder="Nama Belakang Pasien"
                                            v-validate="'required'"
                                            data-vv-as="Nama Belakang Pasien"
                                            name="patientLastName"
                                            v-model="dataPasien.namaBelakang"
                                        />
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-lg-4 col-form-label"
                                        >{{ $t("common.tanggalLahir") }}:<span
                                            style="color:red;"
                                            >*</span
                                        ></label
                                    >
                                    <div class="col-lg-6">
                                        <vue-date-picker
                                            v-model="dataPasien.tglLahir"
                                            :scope="'form-2'"
                                            :validation="'required'"
                                            :validationName="'Tanggal Lahir'"
                                            :name="'tanggalLahir'"
                                            :clearable="false"
                                            :editable="false"
                                            :disabled-date="'notAfterToday'"
                                            :format="dateFormat"
                                        >
                                        </vue-date-picker>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-lg-4 col-form-label"
                                        >{{ $t("common.gender") }}:<span
                                            style="color:red;"
                                            >*</span
                                        ></label
                                    >
                                    <div class="col-lg-6">
                                        <label class="kt-radio">
                                            <input
                                                v-validate="'required'"
                                                type="radio"
                                                class="form-control"
                                                name="jenis_kelamin"
                                                v-model="
                                                    dataPasien.jenisKelamin
                                                "
                                                value="M"
                                            />
                                            Laki-Laki <span></span>
                                        </label>
                                        <label class="kt-radio">
                                            <input
                                                type="radio"
                                                class="form-control"
                                                name="jenis_kelamin"
                                                v-model="
                                                    dataPasien.jenisKelamin
                                                "
                                                value="F"
                                            />
                                            Perempuan <span></span>
                                        </label>
                                    </div>
                                </div>

                                <div class="form-group row">
                                    <label class="col-lg-4 col-form-label"
                                        >{{ $t("common.phoneNo") }}:<span
                                            style="color:red;"
                                            >*</span
                                        ></label
                                    >
                                    <div class="col-lg-6">
                                        <input
                                            class="form-control"
                                            @input="telepon"
                                            type="text"
                                            v-model="dataPasien.noTelepon"
                                        />
                                        <div
                                            v-show="!isNoTelpValid"
                                            style="width: 100%;margin-top: 0.25rem;font-size: 80%;color: #fd397a;"
                                        >
                                            No Telepon Tidak Valid
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-lg-4 col-form-label"
                                        >{{ $t("common.email") }}:<span
                                            style="color:red;"
                                            >*</span
                                        ></label
                                    >
                                    <div class="col-lg-6">
                                        <input
                                            type="email"
                                            class="form-control"
                                            name="email"
                                            placeholder="Email Pasien"
                                            v-model="dataPasien.email"
                                            @input="emailInput"
                                        />
                                        <div
                                            v-show="!isEmailValid"
                                            style="width: 100%;margin-top: 0.25rem;font-size: 80%;color: #fd397a;"
                                        >
                                            Email Tidak Valid
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group row">
                                    <label class="col-lg-4 col-form-label"
                                        >Alamat :<span style="color:red;"
                                            >*</span
                                        ></label
                                    >
                                    <div class="col-lg-6">
                                        <textarea
                                            rows="4"
                                            placeholder="Alamat Pasien"
                                            type="text"
                                            class="form-control"
                                            name="alamat"
                                            v-model="dataPasien.alamat"
                                        />
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-lg-4 col-form-label"
                                        >RT/RW :</label
                                    >
                                    <div class="col-lg-6">
                                        <input
                                            type="text"
                                            class="form-control"
                                            name="rt_rw"
                                            v-model="dataPasien.rtRw"
                                        />
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-lg-4 col-form-label"
                                        >kelurahan :<span style="color:red;"
                                            >*</span
                                        ></label
                                    >
                                    <div class="col-lg-6">
                                        <input
                                            type="text"
                                            class="form-control"
                                            name="kelurahan"
                                            v-model="dataPasien.kelurahan"
                                        />
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-lg-4 col-form-label"
                                        >Kecamatan :<span style="color:red;"
                                            >*</span
                                        ></label
                                    >
                                    <div class="col-lg-6">
                                        <input
                                            type="text"
                                            class="form-control"
                                            name="kecamatan"
                                            v-model="dataPasien.kecamatan"
                                        />
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-lg-4 col-form-label"
                                        >Kabupaten :<span style="color:red;"
                                            >*</span
                                        ></label
                                    >

                                    <div class="col-lg-6">
                                        <input
                                            type="text"
                                            class="form-control"
                                            name="kabupaten"
                                            v-model="dataPasien.kabupaten"
                                        />
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label class="col-lg-5 col-form-label"
                                        >Kewarganegaraan :<span
                                            style="color:red;"
                                            >*</span
                                        ></label
                                    >
                                    <div class="col-lg-6">
                                        <label class="kt-radio">
                                            <input
                                                @click="clearSelectedNation"
                                                v-validate="'required'"
                                                type="radio"
                                                class="form-control"
                                                name="kewarganegaraan"
                                                v-model="
                                                    dataPasien.kewarganegaraan
                                                "
                                                value="Indonesia"
                                            />
                                            WNI <span></span>
                                        </label>
                                        <label class="kt-radio">
                                            <input
                                                type="radio"
                                                class="form-control"
                                                name="kewarganegaraanWna"
                                                v-model="
                                                    dataPasien.kewarganegaraan
                                                "
                                                value="WNA"
                                            />
                                            WNA <span></span>
                                        </label>
                                    </div>
                                </div>

                                <div
                                    class="form-group-row"
                                    v-if="dataPasien.kewarganegaraan == 'WNA'"
                                >
                                    <label
                                        class="col-lg-5 col-form-label"
                                        for="negara"
                                        >Negara :<span style="color:red;"
                                            >*</span
                                        ></label
                                    >
                                    <multiselect
                                        name="negara"
                                        :allow-empty="true"
                                        id="negara"
                                        :searchable="true"
                                        :close-on-select="true"
                                        :options="nations"
                                        label="name"
                                        v-model="dataPasien.negara"
                                    >
                                    </multiselect>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

                <div class="modal-footer">
                    <button
                        @click.prevent="checkProperties"
                        class="button-bar btn btn-brand btn-md btn-tall btn-wide btn-bold btn-upper"
                    >
                        Simpan
                    </button>
                </div>
            </div>
        </div>
        <required-input-modal
            :requiredInput="validationRequired"
            v-if="isShowRequiredModal"
        >
        </required-input-modal>
    </div>
</template>

<script>
import { mapMutations, mapState, mapActions } from "vuex";
import PhoneNumberPatient from "../../_general/PhoneNumber.vue";
import DatePicker from "../../_general/DatePicker.vue";
import VueDatePicker from "../../_general/VueDatePicker.vue";
import Multiselect from "vue-multiselect";
import requiredInputModal from "../create-booking/RequiredModal.vue";
export default {
    name: "EditPatientModal",
    components: {
        PhoneNumberPatient,
        DatePicker,
        VueDatePicker,
        Multiselect,
        requiredInputModal
    },
    data() {
        return {
            dataPasien: {
                nik: null, //
                namaDepan: null, //
                namaBelakang: null, //
                noTelepon: null, //
                email: null, //
                jenisKelamin: null, //
                tempatLahir: null, //
                tglLahir: null, //
                alamat: null,
                rtRw: null, //
                kelurahan: null, //
                kecamatan: null, //
                kabupaten: null, //
                kewarganegaraan: null, //
                negara: null
            },
            validationRequired: { input: [] },
            isNikValid: true,
            dateFormat: "DD-MMM-YYYY",
            isNoTelpValid: true,
            isEmailValid: true
        };
    },
    props: {
        data: {
            type: Object,
            required: true
        },
        modalName: { type: String, default: "EditPatientModal" }
    },
    async created() {
        await this.getNations();
        this.dataPasien = JSON.parse(
            JSON.stringify(this.pastienModalData.data)
        );
        if (this.dataPasien.kewarganegaraan !== "Indonesia") {
            this.nations.forEach(e => {
                if (e.name == this.dataPasien.kewarganegaraan) {
                    this.dataPasien.negara = e;
                }
            });
            this.dataPasien.kewarganegaraan = "WNA";
        } else if (this.dataPasien.kewarganegaraan == "Indonesia") {
            this.dataPasien.negara = null;
            this.dataPasien.kewarganegaraan = "Indonesia";
        } else if (this.dataPasien.kewarganegaraan == "WNI") {
            this.dataPasien.kewarganegaraan = "Indonesia";
        }
    },
    mounted() {
        $("#" + this.modalName).modal();
    },
    computed: {
        ...mapState("DriveThruStore", {
            isModalVisible: state => state.isModalVisible,
            pastienModalData: state => state.pastienModalData,
            nations: state => state.nations,
            isShowRequiredModal: state => state.isShowRequiredModal
        })
    },
    methods: {
        ...mapMutations("DriveThruStore", {
            setModalVisible: "SET_MODAL_VISIBLE",
            setIsShowReqModal: "SET_IS_SHOW_REQUIRED_MODAL",
            setIsShowPatientModal: "SET_IS_SHOW_PATIENT_MODAL"
        }),
        ...mapActions("DriveThruStore", {
            getNations: "getNations",
            updatePatientPcr: "updatePatientPcr"
        }),
        clearSelectedNation() {
            this.dataPasien.negara = null;
        },
        telepon() {
            const pattern = /^(\+62|62)?[\s-]?0?8[1-9]{1}\d{1}[\s-]?\d{4}[\s-]?\d{2,5}$/;
            this.isNoTelpValid = pattern.test(this.dataPasien.noTelepon);
            if (this.isNoTelpValid) {
                this.dataPasien.noTelepon = this.dataPasien.noTelepon.replace(
                    /^(\+62|62|0)/,
                    "62"
                );
                this.dataPasien.noTelepon = this.dataPasien.noTelepon.replace(
                    /\D+/g,
                    ""
                );
            }
        },
        close() {
            $("#" + this.modalName).modal("hide");
            this.setModalVisible(false);
            this.setIsShowPatientModal(false);
        },
        inputSsn() {
            let pattern = /^(1[1-9]|21|[37][1-6]|5[1-3]|6[1-5]|[89][12])\d{2}\d{2}([04][1-9]|[1256][0-9]|[37][01])(0[1-9]|1[0-2])\d{2}\d{4}$/;
            let valid = pattern.test(this[`dataPasien`].nik);
            this.isNikValid = valid;
        },
        emailInput() {
            const pattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
            this.isEmailValid = pattern.test(this.dataPasien.email);
        },
        checkProperties() {
            this.validationRequired = { input: [] };
            let pattern = /^(1[1-9]|21|[37][1-6]|5[1-3]|6[1-5]|[89][12])\d{2}\d{2}([04][1-9]|[1256][0-9]|[37][01])(0[1-9]|1[0-2])\d{2}\d{4}$/;
            let isNikValid = true;
            let notIncluded = [
                "linkKtp",
                "createdBy",
                "creationDate",
                "idMobile",
                "imgFoto",
                "imgKtp",
                "linkFoto",
                "medicalNo",
                "namaBelakang",
                "rtRw",
                "idCare",
                "negara"
            ];
            for (var key in this.dataPasien) {
                if (!notIncluded.includes(key)) {
                    if (
                        this.dataPasien[key] == null ||
                        this.dataPasien[key] == ""
                    ) {
                        if (!this.validationRequired.input) {
                            this.validationRequired.input = [];
                        }
                        this.validationRequired.input.push(
                            `${key} Di Butuhkan`
                        );
                    }
                }
            }
            let dataPasien = JSON.parse(JSON.stringify(this.dataPasien));
            if (dataPasien.nik) {
                isNikValid = pattern.test(dataPasien.nik);
            }
            if (!isNikValid) {
                if (!this.validationRequired.input) {
                    this.validationRequired.input = [];
                }
                this.validationRequired.input.push(`NIK Tidak Valid`);
            }
            if (this.dataPasien.kewarganegaraan == "WNA") {
                if (this.dataPasien.negara !== null) {
                    this.dataPasien.kewarganegaraan = this.dataPasien.negara.name;
                } else if (this.dataPasien.negara == null) {
                    if (!this.validationRequired.input) {
                        this.validationRequired.input = [];
                    }
                    this.validationRequired.input.push("Negara Di Butuhkan");
                }
            }
            if (dataPasien.tglLahir) {
                dataPasien.tglLahir = moment(dataPasien.tglLahir).format(
                    "YYYY-MM-DD"
                );
            }
            if (this.validationRequired.input.length > 0) {
                this.setIsShowReqModal(false);
                setTimeout(() => {
                    this.setIsShowReqModal(true);
                }, 50);
            } else if (this.validationRequired.input.length == 0) {
                this.editPatient(dataPasien);
            }
        },
        editPatient(dataPasien) {
            let dataP = dataPasien;
            dataP.tglLahir = moment(this.dataPasien.tglLahir).format(
                "YYYY-MM-DD"
            );
            delete dataP.negara;
            this.updatePatientPcr({
                data: {
                    data: dataP,
                    id: this.data.encryptedId,
                    testId: this.data.testPemeriksaan[
                        this.pastienModalData.index
                    ].id
                },
                orderId: this.data.orderId
            });
            this.close();
        }
    }
};
</script>

<style></style>
