<template>
    <div>
        <base-header :base-title="headTitle"></base-header>
        <div
            class="kt-content kt-grid__item kt-grid__item--fluid"
            id="kt_content"
        >
            <div class="kt-portlet kt-portlet--mobile">
                <div class="kt-portlet__head kt-portlet__head--lg">
                    <div class="kt-portlet__head-label">
                        <h3
                            style="margin-right: 20px;"
                            class="kt-portlet__head-title"
                        >
                            <button
                                @click="backToPreviousPage"
                                class="btn btn-brand btn-bold btn-upper btn-font-md"
                            >
                                Back
                            </button>
                        </h3>
                        <div v-if="!isBookingLoading">
                            <div
                                v-if="checkFlow('cancel')"
                                style="margin-right: 10px; background-color: #cc3a3a;"
                                :class="
                                    'btn btn-brand btn-bold btn-upper btn-font-md'
                                "
                            >
                                Booking Di Batalkan
                            </div>
                        </div>
                    </div>

                    <div class="kt-portlet__head-toolbar">
                        <div
                            v-if="!isBookingLoading"
                            class="kt-portlet__head-wrapper"
                        >
                            <button
                                @click="changePaymentType"
                                v-if="
                                    !checkFlow('paid') &&
                                        !checkFlow('cancel') &&
                                        checkPermission(
                                            'DriveThruResource.POST.ChangePaymentMethod'
                                        )
                                "
                                class="btn btn-brand btn-bold btn-upper btn-font-md"
                            >
                                Ubah Metode Pembayaran
                            </button>
                        </div>
                    </div>
                </div>
                <div
                    class="kt-portlet__body d-flex flex-column align-items-center"
                    v-if="!isBookingLoading"
                    id="printMe"
                >
                    <div style="margin-bottom: 3rem;text-align: center;">
                        <div class="form-group">
                            <h4
                                style="margin-bottom: 1rem;font-size: 3rem;"
                                class="form-control"
                                v-if="platNo"
                                for=""
                            >
                                {{ platNo }}
                            </h4>
                        </div>
                    </div>

                    <div class="d-flex flex-column align-items-center">
                        <div class="d-flex" style="margin-bottom: 75px;">
                            <b-card
                                :title="
                                    'Informasi Booking' +
                                        ' #' +
                                        dtBooking.orderId
                                "
                                tag="article"
                                class="mb-2 infor-card"
                            >
                                <div
                                    class="d-flex "
                                    style="font-size:large;flex-direction: column;height: 100%;justify-content: space-between;"
                                >
                                    <div>
                                        Jumlah Pasien:&nbsp;
                                        {{ dtBooking.testPemeriksaan.length }}
                                        <div>
                                            Jumlah Pemeriksaan:&nbsp;

                                            {{
                                                dtBooking.testPemeriksaan
                                                    | getPaketSum
                                            }}
                                        </div>
                                        <div>
                                            Tanggal Kunjungan:&nbsp;
                                            {{
                                                dtBooking.tanggalKunjungan
                                                    | tglFilter
                                            }}
                                        </div>
                                        <p>
                                            Status Pembayaran:&nbsp;
                                            {{ dtBooking | getPaymentStatus }}
                                        </p>
                                        <div>
                                            Tipe Pembayaran:&nbsp;
                                            <label
                                                :class="
                                                    dtBooking.paymentType ==
                                                    'ONLINE'
                                                        ? 'btn btn-elevate btn-pill btn-sm mr-2 btn-outline-primary active'
                                                        : 'btn btn-elevate btn-pill btn-sm mr-2 btn-outline-info active'
                                                "
                                            >
                                                {{
                                                    dtBooking.paymentType ==
                                                    "ONLINE"
                                                        ? "ONLINE"
                                                        : "OFFlINE/ DI TEMPAT"
                                                }}
                                            </label>
                                        </div>
                                        <p>
                                            Total Pembayaran:&nbsp;
                                            {{
                                                dtBooking.testPemeriksaan
                                                    | getBookingPrice
                                            }}
                                        </p>
                                        <input
                                            type="hidden"
                                            id="paymentLink"
                                            :value="dtBooking.paymentLink"
                                        />
                                        <p
                                            v-if="
                                                dtBooking.paymentType ==
                                                    'ONLINE'
                                            "
                                            style="font-weight: 500;"
                                        >
                                            Link Pembayaran:&nbsp;
                                            {{ dtBooking.paymentLink }}

                                            <a
                                                style="cursor: pointer;margin-left: 10px;"
                                                @click.prevent="copyPaymentLink"
                                            >
                                                <i class="far fa-copy"></i>
                                            </a>
                                        </p>
                                        <p>
                                            Pasien Utama:&nbsp;
                                            {{
                                                dtBooking.testPemeriksaan
                                                    | getPrimaryName
                                            }}
                                        </p>
                                    </div>
                                    <b-button
                                        v-if="
                                            !checkFlow('paid') &&
                                                !checkFlow('online') &&
                                                !checkFlow('cancel') &&
                                                checkPermission(
                                                    'DriveThruResource.POST.ManualPaid'
                                                )
                                        "
                                        @click="paymentPaid"
                                        variant="primary"
                                        >Ubah Status Pembayaran</b-button
                                    >
                                </div>
                            </b-card>
                            <div class="plat-form plat-form-hide">
                                <label
                                    v-if="
                                        checkPermission(
                                            'DriveThruResource.POST.InputPlatNumber'
                                        ) && !checkFlow('cancel')
                                    "
                                    id="ktp-label"
                                    class="col-lg-12 btn btn-brand btn-md btn-tall btn-wide btn-bold btn-upper"
                                    for="foto-pasien"
                                >
                                    input foto
                                    <input
                                        style="display: none;"
                                        type="file"
                                        name="pasienFoto"
                                        id="foto-pasien"
                                        accept="image/jpeg, image/png, image/jpg"
                                        capture="camera"
                                        @change="setImage"
                                    />
                                </label>
                                <div
                                    style="margin: 3rem; width: 200px; height: 300px"
                                    v-if="!imgSrc"
                                >
                                    <img
                                        :src="
                                            dtBooking.fileNamePlat
                                                ? fotoPlat
                                                : 'https://via.placeholder.com/200x300'
                                        "
                                        width="200"
                                        height="300"
                                    />
                                </div>
                                <div
                                    style="margin: 3rem; width: 200px; height: 300px"
                                    v-else
                                >
                                    <img
                                        :src="imgSrc"
                                        width="200"
                                        height="300"
                                    />
                                    <button
                                        class="cancel-image"
                                        @click="deleteImage"
                                    >
                                        X
                                    </button>
                                </div>
                                <form autocomplete="off">
                                    <input
                                        v-if="
                                            checkPermission(
                                                'DriveThruResource.POST.InputPlatNumber'
                                            ) && !checkFlow('cancel')
                                        "
                                        style="margin-bottom: 1rem;"
                                        v-model="platNo"
                                        type="text"
                                        class="form-control"
                                        placeholder="Plat Nomor"
                                        name="plat"
                                    />

                                    <button
                                        v-if="
                                            checkPermission(
                                                'DriveThruResource.POST.InputPlatNumber'
                                            ) && !checkFlow('cancel')
                                        "
                                        @click.prevent="postPlatNomor()"
                                        class="btn btn-brand btn-md btn-bold btn-upper"
                                    >
                                        Simpan Plat Nomor Dan Foto
                                    </button>
                                </form>
                            </div>
                        </div>

                        <booking-test
                            class="booking-test-cards"
                            style="margin-bottom: 75px "
                            :dtBooking="dtBooking"
                        >
                        </booking-test>
                    </div>

                    <div class="plat-form plat-form-show">
                        <img
                            v-if="!imgSrc"
                            :src="
                                dtBooking.fileNamePlat
                                    ? fotoPlat
                                    : 'https://via.placeholder.com/200x300'
                            "
                            width="200"
                            height="300"
                            style="margin-bottom: 1rem;"
                        />
                        <img
                            v-else
                            :src="imgSrc"
                            width="200"
                            height="300"
                            style="margin-bottom: 1rem;"
                        />
                        <form autocomplete="off">
                            <label
                                v-if="platNo"
                                class="col-lg-4 col-form-label"
                                for="plat"
                                >Plat Nomor:</label
                            >
                            <input
                                v-if="
                                    checkPermission(
                                        'DriveThruResource.POST.InputPlatNumber'
                                    ) && !checkFlow('cancel')
                                "
                                style="margin-bottom: 1rem;"
                                v-model="platNo"
                                type="text"
                                class="form-control"
                                placeholder="Plat Nomor"
                                name="plat"
                            />
                            <label
                                v-if="
                                    checkPermission(
                                        'DriveThruResource.POST.InputPlatNumber'
                                    ) && !checkFlow('cancel')
                                "
                                id="ktp-label"
                                class="col-lg-12 btn btn-brand btn-md btn-tall btn-wide btn-bold btn-upper"
                                for="foto-pasien"
                            >
                                foto
                                <input
                                    style="display: none;"
                                    type="file"
                                    name="pasienFoto"
                                    id="foto-pasien"
                                    accept="image/jpeg, image/png, image/jpg"
                                    capture="camera"
                                    @change="setImage"
                                />
                            </label>
                            <button
                                v-if="
                                    checkPermission(
                                        'DriveThruResource.POST.InputPlatNumber'
                                    ) && !checkFlow('cancel')
                                "
                                @click.prevent="postPlatNomor()"
                                class="btn btn-brand btn-md btn-bold btn-upper"
                            >
                                Simpan Plat Nomor Dan Foto
                            </button>
                        </form>
                    </div>

                    <button
                        v-if="!checkFlow('datang')"
                        @click="confirmArrival"
                        class="button-bar btn btn-brand btn-md btn-tall btn-wide btn-bold btn-upper"
                    >
                        Konfirmasi Pasien Datang
                    </button>
                </div>
                <div v-else class="m-auto p-5 d-flex flex-row h-100">
                    <div
                        class="kt-spinner kt-spinner--v2 kt-spinner--lg kt-spinner--primary my-auto"
                    ></div>
                    <h5 class="my-auto pl-5">Loading ...</h5>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import BaseTitle from "../_base/BaseTitle.vue";
import BaseHeader from "../_base/BaseHeader.vue";
import BookingTest from "./components/bookingTest.vue";
import CheckPermission from "../../services/checkPermission.service";
const checkPermission = new CheckPermission();

import { mapActions, mapState } from "vuex";
export default {
    components: {
        BaseTitle,
        BaseHeader,
        BookingTest
    },
    data() {
        return {
            headTitle: "Booking Detail",
            isLoaded: true,
            platNo: "",
            imgSrc: null,
            fileName: null,
            imgB64: null
        };
    },
    computed: {
        ...mapState("DriveThruStore", {
            dtBooking: state => state.dtBooking,
            isBookingLoading: state => state.isBookingLoading,
            fotoPlat: state => state.fotoPlat,
            isBooking: state => state.isBooking
        })
    },
    methods: {
        ...mapActions("DriveThruStore", {
            getBooking: "getBooking",
            postNoPlat: "postNoPlat",
            pasienDatang: "pasienDatang",
            postUbahMetodePembayaran: "postUbahMetodePembayaran",
            postBookingCancel: "postBookingCancel",
            getPhotoPlat: "getPhotoPlat",
            postManualPaidOffline: "postManualPaidOffline"
        }),
        checkPermission: function(method) {
            return checkPermission.isCanAccess(method);
        },
        deleteImage() {
            this.imgSrc = null;
            this.fileName = null;
            this.imgB64 = null;
            document.getElementById("foto-pasien").value = null;
        },
        copyPaymentLink() {
            let paymentLinkElement = document.querySelector("#paymentLink");
            paymentLinkElement.setAttribute("type", "text");
            paymentLinkElement.select();
            try {
                document.execCommand("copy");
                toastr.success("Berhasil disalin");
            } catch (err) {
                toastr.error("Gagal disalin");
            }
            paymentLinkElement.setAttribute("type", "hidden");
            window.getSelection().removeAllRanges();
        },
        paymentPaid() {
            //
            Swal.fire({
                title: `Ubah Status Pembayaran Lunas Milik ${this.PrimaryName(
                    this.dtBooking.testPemeriksaan
                )} Menjadi Lunas?`,
                confirmButtonText: "IYA",
                cancelButtonText: "TIDAK",
                showCancelButton: true,
                allowOutsideClick: false,
                showCloseButton: true,
                reverseButtons: true
            }).then(result => {
                if (result.value) {
                    this.postManualPaidOffline(this.dtBooking.orderId);
                }
            });
        },
        backToPreviousPage() {
            this.$router.back();
        },
        cancelBooking() {
            //
            Swal.fire({
                title: `Apakah Ingin Membatalkan Booking Milik ${this.PrimaryName(
                    this.dtBooking.testPemeriksaan
                )}`,
                confirmButtonText: "IYA",
                cancelButtonText: "TIDAK",
                showCancelButton: true,
                allowOutsideClick: false,
                showCloseButton: true,
                reverseButtons: true
            }).then(result => {
                if (result.value) {
                    this.postBookingCancel({
                        data: this.dtBooking.encryptedId,
                        orderId: this.dtBooking.orderId
                    });
                }
            });
        },
        changePaymentType() {
            let paymentType = this.dtBooking.paymentType;
            let newPaymentType = null;
            if (paymentType == "OFFLINE") {
                newPaymentType = "ONLINE";
            } else if (paymentType == "ONLINE") {
                newPaymentType = "OFFLINE";
            }
            Swal.fire({
                title: `Apakah Ingin Mengubah Metode Pembayaran ${paymentType} Menjadi ${newPaymentType}`,
                confirmButtonText: "IYA",
                cancelButtonText: "TIDAK",
                showCancelButton: true,
                allowOutsideClick: false,
                showCloseButton: true,
                reverseButtons: true
            }).then(result => {
                if (result.value) {
                    this.postUbahMetodePembayaran({
                        data: { paymentType: newPaymentType },
                        encId: this.dtBooking.encryptedId,
                        orderId: this.dtBooking.orderId
                    });
                }
            });
        },
        setImage(e) {
            const file = e.target.files[0];
            this.fileName = file.name;
            if (file.type.indexOf("image/") === -1) {
                alert("Please select an image file");
                return;
            }
            if (typeof FileReader === "function") {
                const reader = new FileReader();
                reader.onload = event => {
                    this.imgSrc = event.target.result;
                };
                reader.readAsDataURL(file);
            } else {
                alert("Sorry, FileReader API not supported");
            }
        },
        postPlatNomor(bool) {
            let img64 = null;
            if (this.imgSrc) {
                img64 = this.imgSrc.replace("data:image/png;base64,", "");
                img64 = img64.replace("data:image/jpg;base64,", "");
                img64 = img64.replace("data:image/jpeg;base64,", "");
            }
            this.postNoPlat({
                data: {
                    platNo: this.platNo,
                    file: img64,
                    fileName: this.fileName
                        ? this.fileName
                        : this.dtBooking.fileNamePlat
                },
                id: this.dtBooking.orderId,
                bool: bool
            });
        },
        checkPaketPrice() {
            let value = this.dtBooking.testPemeriksaan;
            let price = 0;
            value.forEach(element => {
                element.paketPemeriksaans.forEach(ele => {
                    if (!ele.cancel) {
                        price += ele.paketPemeriksaan.price;
                    }
                });
            });
            if (price > 0) {
                return true;
            }
            return false;
        },
        checkFlow(flow) {
            let flag = false;
            let flowArray = this.dtBooking.statusFlow;
            if (flow == "paid") {
                flowArray.forEach(e => {
                    if (e.status == "PAID" || e.status == "PAID_MANUAL") {
                        flag = true;
                    }
                });
            }
            if (flow == "datang") {
                flowArray.forEach(e => {
                    if (e.status == "PATIENT_DATANG") {
                        flag = true;
                    }
                });
            }
            if (flow == "cancel") {
                flowArray.forEach(e => {
                    if (e.status == "CANCEL" || e.status == "COMPLETE") {
                        flag = true;
                    }
                });
            }
            if (flow == "his") {
                flowArray.forEach(e => {
                    if (e.status == "INPUT_NO_REGISTRASI_HIS") {
                        flag = true;
                    }
                });
            }
            if (flow == "online") {
                if (this.dtBooking.paymentType == "ONLINE") {
                    flag = true;
                }
            }

            return flag;
        },
        confirmArrival() {
            // const isDatang = !this.checkFlow("datang");
            const isPrice = this.checkPaketPrice();
            const isCancel = !this.checkFlow("cancel");
            const isBooking = !this.isBooking;
            const isPaid = this.checkFlow("paid");
            const isHis = this.checkFlow("his");
            if (
                // isDatang &&
                isPrice &&
                isCancel &&
                isBooking &&
                isPaid &&
                isHis
            ) {
                swal.fire({
                    title: "Apakah Pasien sudah sampai?",
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonText: "Sudah Sampai",
                    cancelButtonText: "Belum",
                    allowOutsideClick: false,
                    reverseButtons: true
                }).then(result => {
                    if (result.value) {
                        // if (this.platNo || this.imgSrc) {
                        //     this.postPlatNomor(true);
                        // }
                        this.pasienDatang({
                            orderId: this.dtBooking.orderId
                        });
                    }
                });
            } else {
                let title;
                if (!isPaid) {
                    title = "Pembayaran Belum Lunas";
                }
                if (!isHis) {
                    title = "Silahkan Input No Registrasi";
                }

                if (!title) {
                    title = "Booking Tidak Valid";
                }

                swal.fire({
                    title: title,
                    type: "warning"
                });
            }
        },
        PrimaryName(value) {
            let name = "";
            value.forEach(ele => {
                if (ele.primaryPatient) {
                    name = `${ele.patientLab.namaDepan} ${
                        ele.patientLab.namaBelakang
                            ? ele.patientLab.namaBelakang
                            : ""
                    }`;
                }
            });
            return name;
        }
    },
    filters: {
        getPrimaryName(value) {
            let name = "";
            value.forEach(ele => {
                if (ele.primaryPatient) {
                    name = `${ele.patientLab.namaDepan} ${
                        ele.patientLab.namaBelakang
                            ? ele.patientLab.namaBelakang
                            : ""
                    }`;
                }
            });
            return name;
        },
        getBookingPrice(value) {
            let price = 0;
            value.forEach(element => {
                element.paketPemeriksaans.forEach(ele => {
                    if (!ele.cancel) {
                        price += ele.paketPemeriksaan.price;
                    }
                });
            });
            return price.toLocaleString("id", {
                style: "currency",
                currency: "IDR"
            });
        },
        getPaymentStatus(value) {
            let status = "UNPAID";
            value.statusFlow.forEach(ele => {
                if (ele.status == "PAID") {
                    status = "PAID ONLINE";
                }
                if (ele.status == "PAID_MANUAL") {
                    status = "PAID OFFLINE";
                }
            });
            return status;
        },
        getPaketSum(value) {
            let count = 0;
            value.forEach(ele => {
                ele.paketPemeriksaans.forEach(e => {
                    if (!e.cancel) {
                        count++;
                    }
                });
            });
            return count;
        },

        tglFilter: value => moment(value).format("YYYY-MMM-DD")
    },
    async created() {
        await this.getBooking(this.$route.params.id);
        if (this.dtBooking.platNo) {
            this.platNo = this.dtBooking.platNo;
        }
    },
    mounted() {
        const footer = document.getElementsByClassName(
            "kt-footer kt-grid__item kt-grid kt-grid--desktop kt-grid--ver-desktop"
        );
        if (footer[0]) {
            footer[0].remove();
        }
    }
};
</script>
