<template>
    <div>
        <div
            v-for="(booking, index) in dtBooking.testPemeriksaan"
            :key="booking.id"
            class="row booking-test "
        >
            <div class="flex-column d-flex" style="width: 402px;">
                <div class="flex-row d-flex justify-content-between">
                    <div style="max-width: 285px;">
                        <h3 class="booking-test-name">
                            {{
                                `${booking.patientLab.namaDepan} ${
                                    booking.patientLab.namaBelakang
                                        ? booking.patientLab.namaBelakang
                                        : ""
                                }`
                            }}
                        </h3>
                        <h5 style="color: white;">
                            Tanggal Lahir :
                            {{ booking.patientLab.tglLahir | tglFilter }}
                        </h5>
                        <h5 style="color: white;">
                            NIK : {{ booking.patientLab.nik }}
                        </h5>
                        <h5 style="color: white;">
                            No.Telp : {{ booking.patientLab.noTelepon }}
                        </h5>
                        <h5 style="color: white;">
                            Email : {{ booking.patientLab.email }}
                        </h5>
                        <h5 style="color: white;">
                            HIS :
                            {{
                                booking.noRegistrasiHIS
                                    ? booking.noRegistrasiHIS
                                    : "-"
                            }}
                        </h5>
                        <h5 style="color: white;">
                            MRN : {{ booking.noMrn ? booking.noMrn : "-" }}
                        </h5>
                        <label
                            class="btn btn-elevate btn-pill btn-sm mr-2 btn-outline-success active"
                        >
                            {{ calculatePrice(booking.paketPemeriksaans) }}
                        </label>
                    </div>
                    <div>
                        <b-dropdown
                            dropleft
                            id="dropdownTest"
                            class="m-md-2"
                            :lazy="true"
                        >
                            <template #button-content>
                                <i
                                    style="color: white;"
                                    class="fas fa-ellipsis-h"
                                ></i>
                            </template>

                            <b-dropdown-item
                                v-if="
                                    !checkFlow('cancel') &&
                                        !checkFlow('paid') &&
                                        checkPermission(
                                            'DriveThruResource.POST.PackageDisactivate'
                                        )
                                "
                                @click.prevent="
                                    activateDeactivateTest(
                                        booking.paketPemeriksaans,
                                        'deactivate',
                                        index
                                    )
                                "
                                >Non Aktifkan Paket</b-dropdown-item
                            >
                            <b-dropdown-item
                                v-if="
                                    !checkFlow('cancel') &&
                                        !checkFlow('paid') &&
                                        checkPermission(
                                            'DriveThruResource.POST.PackageActivate'
                                        )
                                "
                                @click.prevent="
                                    activateDeactivateTest(
                                        booking.paketPemeriksaans,
                                        'activate',
                                        index
                                    )
                                "
                                >Aktifkan Paket</b-dropdown-item
                            >
                            <b-dropdown-item
                                v-if="booking.linkQrRegistrasi"
                                @click.prevent="
                                    openQrCode(
                                        booking.linkQrRegistrasi,
                                        booking,
                                        index
                                    )
                                "
                                >Qr Code Registrasi</b-dropdown-item
                            >
                            <b-dropdown-divider
                                v-if="
                                    checkPermission(
                                        'DriveThruResource.POST.EditDataPatient'
                                    )
                                "
                            ></b-dropdown-divider>
                            <b-dropdown-item
                                v-if="
                                    checkPermission(
                                        'DriveThruResource.POST.EditDataPatient'
                                    )
                                "
                                @click.prevent="
                                    openEditPatientModal(
                                        booking.patientLab,
                                        index
                                    )
                                "
                                >Edit Data Pasien</b-dropdown-item
                            >
                        </b-dropdown>
                    </div>
                </div>
                <div class="d-flex align-items-center">
                    <div
                        v-for="(test, index) in booking.paketPemeriksaans"
                        :key="test.id"
                    >
                        <label
                            v-if="index < 2"
                            :style="
                                test.cancel ? 'background-color:#e1c791 ' : ''
                            "
                            class="btn btn-elevate btn-pill btn-sm mr-2 btn-outline-warning active pill-for pill-test"
                            >{{ test.paketPemeriksaan.nama }}</label
                        >
                        <label
                            v-else-if="test.length == 0"
                            :class="
                                'btn btn-elevate btn-pill btn-sm mr-2 btn-outline-warning pill-test'
                            "
                        >
                            {{ "KOSONG" }}
                        </label>
                    </div>
                    <label
                        v-if="booking.paketPemeriksaans.length > 2"
                        :class="
                            'btn btn-elevate btn-pill btn-sm mr-2 btn-outline-danger'
                        "
                        style=""
                    >
                        +
                        {{ booking.paketPemeriksaans.length - 2 }}...
                    </label>
                </div>
            </div>

            <!-- hanya kasir dan fo -->
            <div
                class="d-flex flex-row"
                style="justify-content: space-between;width: 100%;"
            >
                <div>
                    <b-button
                        v-if="
                            checkPermission(
                                'DriveThruResource.POST.InputHis'
                            ) && !checkFlow('cancel')
                        "
                        style="margin-top:10px"
                        v-b-toggle="`collapse-${index}`"
                        variant="primary"
                        >Masukkan Nomor His</b-button
                    >
                    <b-collapse :id="`collapse-${index}`" class="mt-2">
                        <form
                            style="display: flex;flex-direction: column;color:white;"
                            autocomplete="off"
                        >
                            HIS:
                            <input
                                autocomplete="false"
                                v-model="RhisMultiple[index].registrasiHIS"
                                placeholder="NO. REGISTRASI HIS"
                                type="text"
                                name="registrasiHIS"
                                id=""
                            />
                            MRN:
                            <input
                                autocomplete="false"
                                v-model="RhisMultiple[index].noMrn"
                                placeholder="MRN"
                                type="text"
                                name="MRN"
                                id=""
                            />
                            <button
                                @click.prevent="postRhis(index)"
                                class="btn btn-brand btn-md btn-bold btn-upper"
                                style="padding: 0.25rem 1rem !important;"
                            >
                                SAVE
                            </button>
                        </form>
                    </b-collapse>
                </div>
                <div>
                    <img
                        @click.prevent="
                            openQrCode(booking.linkQrRegistrasi, booking, index)
                        "
                        :src="booking.linkQrRegistrasi"
                        width="80px"
                        height="80px"
                        style="cursor: pointer;"
                    />
                </div>
            </div>

            <qr-code-modal
                :modalName="modalName"
                v-if="isModalVisible"
            ></qr-code-modal>
            <edit-patient-modal :data="dtBooking" v-if="isShowPatientModal">
            </edit-patient-modal>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import CheckPermission from "../../../services/checkPermission.service";
const checkPermission = new CheckPermission();
import QrCodeModal from "../components/QrCodeModal.vue";
import EditPatientModal from "../components/EditPatientModal.vue";


export default {
    name: "booking_test",
    components: {
        QrCodeModal,
        EditPatientModal
    },
    data() {
        return {
            paketOptions: {},
            canceledPaketOptions: {},
            modalName: "qrCodeModal",
            RhisMultiple: []
        };
    },
    props: {
        dtBooking: {
            type: Object,
            required: true
        }
    },
    computed: {
        ...mapState("DriveThruStore", {
            isModalVisible: state => state.isModalVisible,
            isShowPatientModal: state => state.isShowPatientModal,
            isShowRequiredModal: state => state.isShowRequiredModal
        })
    },
    filters: {
        tglFilter: value => moment(value).format("YYYY-MMM-DD")
    },
    methods: {
        ...mapMutations("DriveThruStore", {
            setModalVisible: "SET_MODAL_VISIBLE",
            setQrCodeLink: "SET_QR_CODE_LINK",
            setIsShowPatientModal: "SET_IS_SHOW_PATIENT_MODAL",
            setPatientModalData: "SET_PATIENT_MODAL_DATA",
            setIsShowRequiredModal: "SET_IS_SHOW_REQUIRED_MODAL",
            setIsFotoPlat: "SET_IS_FOTO_PLAT",
            setDataBooking: "SET_DATA_BOOKING",
            setModalVisible: "SET_MODAL_VISIBLE",
            setIsShowReqModal: "SET_IS_SHOW_REQUIRED_MODAL",
            setIsShowPatientModal: "SET_IS_SHOW_PATIENT_MODAL"
        }),
        ...mapActions("DriveThruStore", {
            updatePaketPatient: "updatePaketPatient",
            postNoHis: "postNoHis",
            getPhotoPlat: "getPhotoPlat"
        }),
        openEditPatientModal(patient, index) {
            this.setIsShowPatientModal(false);
            this.setPatientModalData({ data: patient, index: index });
            setTimeout(() => {
                this.setIsShowPatientModal(true);
            }, 50);
        },
        openQrCode(link, booking, index) {
            this.setIsFotoPlat(false);
            this.setQrCodeLink(link);
            this.setDataBooking({ booking, index });
            this.setModalVisible(false);
            setTimeout(() => {
                this.setModalVisible(true);
            }, 50);
        },
        checkPermission: function(method) {
            return checkPermission.isCanAccess(method);
        },
        calculatePrice(array) {
            let price = 0;
            array.forEach(element => {
                if (!element.cancel) {
                    price += element.paketPemeriksaan.price;
                }
            });
            if (price > 0) {
                return price.toLocaleString("id", {
                    style: "currency",
                    currency: "IDR"
                });
            }
            return "0".toLocaleString("id", {
                style: "currency",
                currency: "IDR"
            });
        },
        postRhis(index) {
            let data = {
                hisRefNumber: this.RhisMultiple[index].registrasiHIS,
                mrn: this.RhisMultiple[index].noMrn,
                testId: this.dtBooking.testPemeriksaan[index].id,
                orderId: this.dtBooking.orderId,
                tglKedatangan: moment().format("YYYY-MM-DD HH:mm:ss")
            };
            this.postNoHis({ data, id: this.dtBooking.orderId });
        },
        activateDeactivateTest(testPemeriksaanObj, command, index) {
            if (command == "deactivate") {
                let valid = testPemeriksaanObj.filter(e => {
                    if (e.cancel == false) {
                        return e;
                    }
                });
                let paketNames = {};
                valid.forEach(element => {
                    paketNames[element.id] = element.paketPemeriksaan.nama;
                });
                this.paketOptions = paketNames;
                this.deactivateTestConfirmation(command, index);
            } else if (command == "activate") {
                let notValid = testPemeriksaanObj.filter(e => {
                    if (e.cancel == true) {
                        return e;
                    }
                });
                let paketNames = {};
                notValid.forEach(element => {
                    paketNames[element.id] = element.paketPemeriksaan.nama;
                });
                this.canceledPaketOptions = paketNames;
                this.deactivateTestConfirmation(command, index);
            }
        },
        mapPaketTestData(array, command, idPaket, index, mainObj) {
            if (command == "deactivate") {
                let testPaketList = [];
                let data = array[index].paketPemeriksaans;
                let encryptedId = mainObj.encryptedId;
                data.forEach(e => {
                    if (idPaket.includes(e.id)) {
                        testPaketList.push({ id: e.id, cancel: true });
                    } else {
                        testPaketList.push({ id: e.id, cancel: e.cancel });
                    }
                });

                this.updatePaketPatient({
                    data: {
                        testId: array[index].id,
                        testPaketList
                    },
                    id: encryptedId, //ENCRYPTEDID,
                    orderId: mainObj.orderId
                });
            } else if (command == "activate") {
                let testPaketList = [];
                let data = array[index].paketPemeriksaans;
                let encryptedId = mainObj.encryptedId;
                data.forEach(e => {
                    if (idPaket.includes(e.id)) {
                        testPaketList.push({ id: e.id, cancel: false });
                    } else {
                        testPaketList.push({ id: e.id, cancel: e.cancel });
                    }
                });

                this.updatePaketPatient({
                    data: {
                        testId: array[index].id,
                        testPaketList
                    },
                    id: encryptedId,
                    orderId: mainObj.orderId
                });
            }
        },
        deactivateTestConfirmation(command, index) {
            if (command == "deactivate") {
                if (Object.keys(this.paketOptions).length == 0) {
                    swal.fire({
                        title: "Tidak ada paket yang dapat di nonaktifkan"
                    });
                } else {
                    let htmlCheckBox = `<div class="form-check">`;
                    let values = [];
                    Object.keys(this.paketOptions).forEach(element => {
                        values.push(+element);
                        htmlCheckBox += `
                            <div style="margin-bottom: 15px;">
                                <input class="form-check-input" type="checkbox" value="${element}" id="${element}">
                                <label class="form-check-label" for="${element}">
                                    ${this.paketOptions[element]}
                                </label>
                            </div>
                        `;
                    });
                    htmlCheckBox += `</div>`;
                    swal.fire({
                        title: "Pilih Paket Untuk Di Batalkan",
                        type: "warning",
                        showCancelButton: true,
                        html: htmlCheckBox,
                        confirmButtonText: "Batalkan",
                        cancelButtonText: "Cancel",
                        allowOutsideClick: false,
                        reverseButtons: true,
                        preConfirm: () => {
                            let result = [];
                            values.forEach((e, index) => {
                                let check = document.getElementById(`${e}`)
                                    .checked;
                                if (check) {
                                    result.push(+e);
                                }
                            });

                            return result;
                        }
                    }).then(result => {
                        if (result.value) {
                            this.mapPaketTestData(
                                this.dtBooking.testPemeriksaan,
                                command,
                                result.value,
                                index,
                                this.dtBooking
                            );
                        }
                    });
                }
            } else if (command == "activate") {
                if (Object.keys(this.canceledPaketOptions).length == 0) {
                    swal.fire({
                        title: "Tidak ada paket yang dapat di aktifkan"
                    });
                } else {
                    let htmlCheckBox = `<div class="form-check">`;
                    let values = [];
                    Object.keys(this.canceledPaketOptions).forEach(element => {
                        values.push(+element);
                        htmlCheckBox += `<div style="margin-bottom: 15px;">
                            <input class="form-check-input" type="checkbox" value="${element}" id="${element}">
                            <label class="form-check-label" for="${element}">
                                ${this.canceledPaketOptions[element]}
                            </label>
                        </div>`;
                    });
                    htmlCheckBox += `</div>`;
                    swal.fire({
                        title: "Pilih Paket Untuk Di Aktifkan",
                        type: "warning",
                        showCancelButton: true,
                        html: htmlCheckBox,
                        confirmButtonText: "Aktifkan",
                        cancelButtonText: "Cancel",
                        allowOutsideClick: false,
                        reverseButtons: true,
                        preConfirm: () => {
                            let result = [];
                            values.forEach((e, index) => {
                                let check = document.getElementById(`${e}`)
                                    .checked;
                                if (check) {
                                    result.push(+e);
                                }
                            });

                            return result;
                        }
                    }).then(result => {
                        if (result.value) {
                            this.mapPaketTestData(
                                this.dtBooking.testPemeriksaan,
                                command,
                                result.value,
                                index,
                                this.dtBooking
                            );
                        }
                    });
                }
            }
        },
        checkFlow(flow) {
            let strFlow = JSON.stringify(this.dtBooking.statusFlow);
            let flowArray = JSON.parse(strFlow);
            let flag = false;
            if (flow == "paid") {
                flowArray.forEach(e => {
                    if (e.status == "PAID" || e.status == "PAID_MANUAL") {
                        flag = true;
                    }
                });
            }
            if (flow == "his") {
                flowArray.forEach(e => {
                    if (e.status == "INPUT_NO_REGISTRASI_HIS") {
                        flag = true;
                    }
                });
            }
            if (flow == "cancel") {
                flowArray.forEach(e => {
                    if (e.status == "CANCEL") {
                        flag = true;
                    }
                });
            }
            return flag;
        }
    },
    created() {
        this.dtBooking.testPemeriksaan.forEach((element, index) => {
            this.RhisMultiple.push({
                registrasiHIS: element.noRegistrasiHIS,
                noMrn: element.noMrn
            });
        });
        this.setModalVisible(false);
        this.setIsShowPatientModal(false);
        this.setIsShowReqModal(false);
    }
};
</script>

<style></style>
