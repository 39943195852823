var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',_vm._l((_vm.dtBooking.testPemeriksaan),function(booking,index){return _c('div',{key:booking.id,staticClass:"row booking-test"},[_c('div',{staticClass:"flex-column d-flex",staticStyle:{"width":"402px"}},[_c('div',{staticClass:"flex-row d-flex justify-content-between"},[_c('div',{staticStyle:{"max-width":"285px"}},[_c('h3',{staticClass:"booking-test-name"},[_vm._v(" "+_vm._s(`${booking.patientLab.namaDepan} ${ booking.patientLab.namaBelakang ? booking.patientLab.namaBelakang : "" }`)+" ")]),_c('h5',{staticStyle:{"color":"white"}},[_vm._v(" Tanggal Lahir : "+_vm._s(_vm._f("tglFilter")(booking.patientLab.tglLahir))+" ")]),_c('h5',{staticStyle:{"color":"white"}},[_vm._v(" NIK : "+_vm._s(booking.patientLab.nik)+" ")]),_c('h5',{staticStyle:{"color":"white"}},[_vm._v(" No.Telp : "+_vm._s(booking.patientLab.noTelepon)+" ")]),_c('h5',{staticStyle:{"color":"white"}},[_vm._v(" Email : "+_vm._s(booking.patientLab.email)+" ")]),_c('h5',{staticStyle:{"color":"white"}},[_vm._v(" HIS : "+_vm._s(booking.noRegistrasiHIS ? booking.noRegistrasiHIS : "-")+" ")]),_c('h5',{staticStyle:{"color":"white"}},[_vm._v(" MRN : "+_vm._s(booking.noMrn ? booking.noMrn : "-")+" ")]),_c('label',{staticClass:"btn btn-elevate btn-pill btn-sm mr-2 btn-outline-success active"},[_vm._v(" "+_vm._s(_vm.calculatePrice(booking.paketPemeriksaans))+" ")])]),_c('div',[_c('b-dropdown',{staticClass:"m-md-2",attrs:{"dropleft":"","id":"dropdownTest","lazy":true},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('i',{staticClass:"fas fa-ellipsis-h",staticStyle:{"color":"white"}})]},proxy:true}],null,true)},[(
                                !_vm.checkFlow('cancel') &&
                                    !_vm.checkFlow('paid') &&
                                    _vm.checkPermission(
                                        'DriveThruResource.POST.PackageDisactivate'
                                    )
                            )?_c('b-dropdown-item',{on:{"click":function($event){$event.preventDefault();return _vm.activateDeactivateTest(
                                    booking.paketPemeriksaans,
                                    'deactivate',
                                    index
                                )}}},[_vm._v("Non Aktifkan Paket")]):_vm._e(),(
                                !_vm.checkFlow('cancel') &&
                                    !_vm.checkFlow('paid') &&
                                    _vm.checkPermission(
                                        'DriveThruResource.POST.PackageActivate'
                                    )
                            )?_c('b-dropdown-item',{on:{"click":function($event){$event.preventDefault();return _vm.activateDeactivateTest(
                                    booking.paketPemeriksaans,
                                    'activate',
                                    index
                                )}}},[_vm._v("Aktifkan Paket")]):_vm._e(),(booking.linkQrRegistrasi)?_c('b-dropdown-item',{on:{"click":function($event){$event.preventDefault();return _vm.openQrCode(
                                    booking.linkQrRegistrasi,
                                    booking,
                                    index
                                )}}},[_vm._v("Qr Code Registrasi")]):_vm._e(),(
                                _vm.checkPermission(
                                    'DriveThruResource.POST.EditDataPatient'
                                )
                            )?_c('b-dropdown-divider'):_vm._e(),(
                                _vm.checkPermission(
                                    'DriveThruResource.POST.EditDataPatient'
                                )
                            )?_c('b-dropdown-item',{on:{"click":function($event){$event.preventDefault();return _vm.openEditPatientModal(
                                    booking.patientLab,
                                    index
                                )}}},[_vm._v("Edit Data Pasien")]):_vm._e()],1)],1)]),_c('div',{staticClass:"d-flex align-items-center"},[_vm._l((booking.paketPemeriksaans),function(test,index){return _c('div',{key:test.id},[(index < 2)?_c('label',{staticClass:"btn btn-elevate btn-pill btn-sm mr-2 btn-outline-warning active pill-for pill-test",style:(test.cancel ? 'background-color:#e1c791 ' : '')},[_vm._v(_vm._s(test.paketPemeriksaan.nama))]):(test.length == 0)?_c('label',{class:'btn btn-elevate btn-pill btn-sm mr-2 btn-outline-warning pill-test'},[_vm._v(" "+_vm._s("KOSONG")+" ")]):_vm._e()])}),(booking.paketPemeriksaans.length > 2)?_c('label',{class:'btn btn-elevate btn-pill btn-sm mr-2 btn-outline-danger'},[_vm._v(" + "+_vm._s(booking.paketPemeriksaans.length - 2)+"... ")]):_vm._e()],2)]),_c('div',{staticClass:"d-flex flex-row",staticStyle:{"justify-content":"space-between","width":"100%"}},[_c('div',[(
                        _vm.checkPermission(
                            'DriveThruResource.POST.InputHis'
                        ) && !_vm.checkFlow('cancel')
                    )?_c('b-button',{directives:[{name:"b-toggle",rawName:"v-b-toggle",value:(`collapse-${index}`),expression:"`collapse-${index}`"}],staticStyle:{"margin-top":"10px"},attrs:{"variant":"primary"}},[_vm._v("Masukkan Nomor His")]):_vm._e(),_c('b-collapse',{staticClass:"mt-2",attrs:{"id":`collapse-${index}`}},[_c('form',{staticStyle:{"display":"flex","flex-direction":"column","color":"white"},attrs:{"autocomplete":"off"}},[_vm._v(" HIS: "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.RhisMultiple[index].registrasiHIS),expression:"RhisMultiple[index].registrasiHIS"}],attrs:{"autocomplete":"false","placeholder":"NO. REGISTRASI HIS","type":"text","name":"registrasiHIS","id":""},domProps:{"value":(_vm.RhisMultiple[index].registrasiHIS)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.RhisMultiple[index], "registrasiHIS", $event.target.value)}}}),_vm._v(" MRN: "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.RhisMultiple[index].noMrn),expression:"RhisMultiple[index].noMrn"}],attrs:{"autocomplete":"false","placeholder":"MRN","type":"text","name":"MRN","id":""},domProps:{"value":(_vm.RhisMultiple[index].noMrn)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.RhisMultiple[index], "noMrn", $event.target.value)}}}),_c('button',{staticClass:"btn btn-brand btn-md btn-bold btn-upper",staticStyle:{"padding":"0.25rem 1rem !important"},on:{"click":function($event){$event.preventDefault();return _vm.postRhis(index)}}},[_vm._v(" SAVE ")])])])],1),_c('div',[_c('img',{staticStyle:{"cursor":"pointer"},attrs:{"src":booking.linkQrRegistrasi,"width":"80px","height":"80px"},on:{"click":function($event){$event.preventDefault();return _vm.openQrCode(booking.linkQrRegistrasi, booking, index)}}})])]),(_vm.isModalVisible)?_c('qr-code-modal',{attrs:{"modalName":_vm.modalName}}):_vm._e(),(_vm.isShowPatientModal)?_c('edit-patient-modal',{attrs:{"data":_vm.dtBooking}}):_vm._e()],1)}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }