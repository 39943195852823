var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('base-header',{attrs:{"base-title":_vm.headTitle}}),_c('div',{staticClass:"kt-content kt-grid__item kt-grid__item--fluid",attrs:{"id":"kt_content"}},[_c('div',{staticClass:"kt-portlet kt-portlet--mobile"},[_c('div',{staticClass:"kt-portlet__head kt-portlet__head--lg"},[_c('div',{staticClass:"kt-portlet__head-label"},[_c('h3',{staticClass:"kt-portlet__head-title",staticStyle:{"margin-right":"20px"}},[_c('button',{staticClass:"btn btn-brand btn-bold btn-upper btn-font-md",on:{"click":_vm.backToPreviousPage}},[_vm._v(" Back ")])]),(!_vm.isBookingLoading)?_c('div',[(_vm.checkFlow('cancel'))?_c('div',{class:'btn btn-brand btn-bold btn-upper btn-font-md',staticStyle:{"margin-right":"10px","background-color":"#cc3a3a"}},[_vm._v(" Booking Di Batalkan ")]):_vm._e()]):_vm._e()]),_c('div',{staticClass:"kt-portlet__head-toolbar"},[(!_vm.isBookingLoading)?_c('div',{staticClass:"kt-portlet__head-wrapper"},[(
                                !_vm.checkFlow('paid') &&
                                    !_vm.checkFlow('cancel') &&
                                    _vm.checkPermission(
                                        'DriveThruResource.POST.ChangePaymentMethod'
                                    )
                            )?_c('button',{staticClass:"btn btn-brand btn-bold btn-upper btn-font-md",on:{"click":_vm.changePaymentType}},[_vm._v(" Ubah Metode Pembayaran ")]):_vm._e()]):_vm._e()])]),(!_vm.isBookingLoading)?_c('div',{staticClass:"kt-portlet__body d-flex flex-column align-items-center",attrs:{"id":"printMe"}},[_c('div',{staticStyle:{"margin-bottom":"3rem","text-align":"center"}},[_c('div',{staticClass:"form-group"},[(_vm.platNo)?_c('h4',{staticClass:"form-control",staticStyle:{"margin-bottom":"1rem","font-size":"3rem"},attrs:{"for":""}},[_vm._v(" "+_vm._s(_vm.platNo)+" ")]):_vm._e()])]),_c('div',{staticClass:"d-flex flex-column align-items-center"},[_c('div',{staticClass:"d-flex",staticStyle:{"margin-bottom":"75px"}},[_c('b-card',{staticClass:"mb-2 infor-card",attrs:{"title":'Informasi Booking' +
                                    ' #' +
                                    _vm.dtBooking.orderId,"tag":"article"}},[_c('div',{staticClass:"d-flex",staticStyle:{"font-size":"large","flex-direction":"column","height":"100%","justify-content":"space-between"}},[_c('div',[_vm._v(" Jumlah Pasien:  "+_vm._s(_vm.dtBooking.testPemeriksaan.length)+" "),_c('div',[_vm._v(" Jumlah Pemeriksaan:  "+_vm._s(_vm._f("getPaketSum")(_vm.dtBooking.testPemeriksaan))+" ")]),_c('div',[_vm._v(" Tanggal Kunjungan:  "+_vm._s(_vm._f("tglFilter")(_vm.dtBooking.tanggalKunjungan))+" ")]),_c('p',[_vm._v(" Status Pembayaran:  "+_vm._s(_vm._f("getPaymentStatus")(_vm.dtBooking))+" ")]),_c('div',[_vm._v(" Tipe Pembayaran:  "),_c('label',{class:_vm.dtBooking.paymentType ==
                                                'ONLINE'
                                                    ? 'btn btn-elevate btn-pill btn-sm mr-2 btn-outline-primary active'
                                                    : 'btn btn-elevate btn-pill btn-sm mr-2 btn-outline-info active'},[_vm._v(" "+_vm._s(_vm.dtBooking.paymentType == "ONLINE" ? "ONLINE" : "OFFlINE/ DI TEMPAT")+" ")])]),_c('p',[_vm._v(" Total Pembayaran:  "+_vm._s(_vm._f("getBookingPrice")(_vm.dtBooking.testPemeriksaan))+" ")]),_c('input',{attrs:{"type":"hidden","id":"paymentLink"},domProps:{"value":_vm.dtBooking.paymentLink}}),(
                                            _vm.dtBooking.paymentType ==
                                                'ONLINE'
                                        )?_c('p',{staticStyle:{"font-weight":"500"}},[_vm._v(" Link Pembayaran:  "+_vm._s(_vm.dtBooking.paymentLink)+" "),_c('a',{staticStyle:{"cursor":"pointer","margin-left":"10px"},on:{"click":function($event){$event.preventDefault();return _vm.copyPaymentLink.apply(null, arguments)}}},[_c('i',{staticClass:"far fa-copy"})])]):_vm._e(),_c('p',[_vm._v(" Pasien Utama:  "+_vm._s(_vm._f("getPrimaryName")(_vm.dtBooking.testPemeriksaan))+" ")])]),(
                                        !_vm.checkFlow('paid') &&
                                            !_vm.checkFlow('online') &&
                                            !_vm.checkFlow('cancel') &&
                                            _vm.checkPermission(
                                                'DriveThruResource.POST.ManualPaid'
                                            )
                                    )?_c('b-button',{attrs:{"variant":"primary"},on:{"click":_vm.paymentPaid}},[_vm._v("Ubah Status Pembayaran")]):_vm._e()],1)]),_c('div',{staticClass:"plat-form plat-form-hide"},[(
                                    _vm.checkPermission(
                                        'DriveThruResource.POST.InputPlatNumber'
                                    ) && !_vm.checkFlow('cancel')
                                )?_c('label',{staticClass:"col-lg-12 btn btn-brand btn-md btn-tall btn-wide btn-bold btn-upper",attrs:{"id":"ktp-label","for":"foto-pasien"}},[_vm._v(" input foto "),_c('input',{staticStyle:{"display":"none"},attrs:{"type":"file","name":"pasienFoto","id":"foto-pasien","accept":"image/jpeg, image/png, image/jpg","capture":"camera"},on:{"change":_vm.setImage}})]):_vm._e(),(!_vm.imgSrc)?_c('div',{staticStyle:{"margin":"3rem","width":"200px","height":"300px"}},[_c('img',{attrs:{"src":_vm.dtBooking.fileNamePlat
                                            ? _vm.fotoPlat
                                            : 'https://via.placeholder.com/200x300',"width":"200","height":"300"}})]):_c('div',{staticStyle:{"margin":"3rem","width":"200px","height":"300px"}},[_c('img',{attrs:{"src":_vm.imgSrc,"width":"200","height":"300"}}),_c('button',{staticClass:"cancel-image",on:{"click":_vm.deleteImage}},[_vm._v(" X ")])]),_c('form',{attrs:{"autocomplete":"off"}},[(
                                        _vm.checkPermission(
                                            'DriveThruResource.POST.InputPlatNumber'
                                        ) && !_vm.checkFlow('cancel')
                                    )?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.platNo),expression:"platNo"}],staticClass:"form-control",staticStyle:{"margin-bottom":"1rem"},attrs:{"type":"text","placeholder":"Plat Nomor","name":"plat"},domProps:{"value":(_vm.platNo)},on:{"input":function($event){if($event.target.composing)return;_vm.platNo=$event.target.value}}}):_vm._e(),(
                                        _vm.checkPermission(
                                            'DriveThruResource.POST.InputPlatNumber'
                                        ) && !_vm.checkFlow('cancel')
                                    )?_c('button',{staticClass:"btn btn-brand btn-md btn-bold btn-upper",on:{"click":function($event){$event.preventDefault();return _vm.postPlatNomor()}}},[_vm._v(" Simpan Plat Nomor Dan Foto ")]):_vm._e()])])],1),_c('booking-test',{staticClass:"booking-test-cards",staticStyle:{"margin-bottom":"75px"},attrs:{"dtBooking":_vm.dtBooking}})],1),_c('div',{staticClass:"plat-form plat-form-show"},[(!_vm.imgSrc)?_c('img',{staticStyle:{"margin-bottom":"1rem"},attrs:{"src":_vm.dtBooking.fileNamePlat
                                ? _vm.fotoPlat
                                : 'https://via.placeholder.com/200x300',"width":"200","height":"300"}}):_c('img',{staticStyle:{"margin-bottom":"1rem"},attrs:{"src":_vm.imgSrc,"width":"200","height":"300"}}),_c('form',{attrs:{"autocomplete":"off"}},[(_vm.platNo)?_c('label',{staticClass:"col-lg-4 col-form-label",attrs:{"for":"plat"}},[_vm._v("Plat Nomor:")]):_vm._e(),(
                                _vm.checkPermission(
                                    'DriveThruResource.POST.InputPlatNumber'
                                ) && !_vm.checkFlow('cancel')
                            )?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.platNo),expression:"platNo"}],staticClass:"form-control",staticStyle:{"margin-bottom":"1rem"},attrs:{"type":"text","placeholder":"Plat Nomor","name":"plat"},domProps:{"value":(_vm.platNo)},on:{"input":function($event){if($event.target.composing)return;_vm.platNo=$event.target.value}}}):_vm._e(),(
                                _vm.checkPermission(
                                    'DriveThruResource.POST.InputPlatNumber'
                                ) && !_vm.checkFlow('cancel')
                            )?_c('label',{staticClass:"col-lg-12 btn btn-brand btn-md btn-tall btn-wide btn-bold btn-upper",attrs:{"id":"ktp-label","for":"foto-pasien"}},[_vm._v(" foto "),_c('input',{staticStyle:{"display":"none"},attrs:{"type":"file","name":"pasienFoto","id":"foto-pasien","accept":"image/jpeg, image/png, image/jpg","capture":"camera"},on:{"change":_vm.setImage}})]):_vm._e(),(
                                _vm.checkPermission(
                                    'DriveThruResource.POST.InputPlatNumber'
                                ) && !_vm.checkFlow('cancel')
                            )?_c('button',{staticClass:"btn btn-brand btn-md btn-bold btn-upper",on:{"click":function($event){$event.preventDefault();return _vm.postPlatNomor()}}},[_vm._v(" Simpan Plat Nomor Dan Foto ")]):_vm._e()])]),(!_vm.checkFlow('datang'))?_c('button',{staticClass:"button-bar btn btn-brand btn-md btn-tall btn-wide btn-bold btn-upper",on:{"click":_vm.confirmArrival}},[_vm._v(" Konfirmasi Pasien Datang ")]):_vm._e()]):_c('div',{staticClass:"m-auto p-5 d-flex flex-row h-100"},[_c('div',{staticClass:"kt-spinner kt-spinner--v2 kt-spinner--lg kt-spinner--primary my-auto"}),_c('h5',{staticClass:"my-auto pl-5"},[_vm._v("Loading ...")])])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }