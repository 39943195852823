<template>
    <div
        class="modal fade"
        :id="modalName"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalScrollableTitle"
        aria-hidden="true"
    >
        <div
            class="modal-dialog modal-dialog-centered modal-dialog-scrollable"
            role="document"
        >
            <div class="modal-content">
                <div class="modal-header">
                    <h5
                        class="modal-title"
                        id="exampleModalScrollableTitle"
                    ></h5>
                    <button
                        type="button"
                        class="close"
                        data-dismiss="modal"
                        aria-label="Close"
                        @click="close()"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body" style="overflow: auto">
                    <div
                        v-for="(error, key, index) in requiredInput"
                        :key="index"
                    >
                        <!-- {{ error }} -->
                        <h1 style="color: black;">Formulir Pasien {{ key }}</h1>
                        <div>
                            <div
                                v-for="(err, index) in error"
                                :key="index + err"
                            >
                                <h3>
                                    {{
                                        !checkValid(err) && index == 0
                                            ? "KOLOM KOSONG"
                                            : ""
                                    }}
                                </h3>
                                <h5 style="color: red;" v-if="!checkValid(err)">
                                    {{ err.replace(" Di Butuhkan", "") }}
                                </h5>
                            </div>
                            <h3>
                                {{
                                    checkValid(error, true)
                                        ? "KOLOM TIDAK VALID"
                                        : ""
                                }}
                            </h3>
                            <div
                                v-for="(err, index) in error"
                                :key="err + index + err"
                            >
                                <h5 style="color: red;" v-if="checkValid(err)">
                                    {{ err.replace("Tidak Valid", "") }}
                                </h5>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="modal-footer"></div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
export default {
    name: "inputRequiredModal",
    props: {
        modalName: { type: String, default: "RequiredModal" },
        requiredInput: { type: Object, default: {} }
    },
    mounted() {
        $("#" + this.modalName).modal();
    },
    methods: {
        ...mapMutations("DriveThruStore", {
            setIsShowRequiredModal: "SET_IS_SHOW_REQUIRED_MODAL"
        }),
        close() {
            $("#" + this.modalName).modal("hide");
            this.setIsShowRequiredModal(false);
        },
        checkValid(err, bool) {
            if (!bool) {
                if (err.includes("Valid")) {
                    return true;
                }
                return false;
            } else {
                let flag = false;
                err.forEach(e => {
                    if (e.includes("Valid")) {
                        flag = true;
                    }
                });
                return flag;
            }
        }
    }
};
</script>

<style></style>
