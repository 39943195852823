<template>
    <div
        class="modal fade"
        :id="modalName"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalScrollableTitle"
        aria-hidden="true"
    >
        <div
            class="modal-dialog modal-dialog-centered modal-dialog-scrollable"
            role="document"
        >
            <div class="modal-content">
                <div class="modal-header">
                    <h5
                        class="modal-title"
                        id="exampleModalScrollableTitle"
                    ></h5>
                    <button
                        type="button"
                        class="close"
                        data-dismiss="modal"
                        aria-label="Close"
                        @click="close()"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div style="overflow: auto;" class="modal-body">
                    <form
                        v-if="
                            !checkFlow('cancel') &&
                                checkPermission(
                                    'DriveThruResource.POST.InputHis'
                                )
                        "
                        style="display: flex;flex-direction: column;border: 1px solid #adadad;padding: 15px;border-radius: 8px;"
                        autocomplete="off"
                    >
                        <div
                            style="display: flex;justify-content: center;margin-bottom: 10px;"
                            class="form-group row"
                        >
                            <label style="margin-right: 20px;">
                                HIS:
                            </label>

                            <input
                                autocomplete="false"
                                v-model="noHis"
                                placeholder="NO. REGISTRASI HIS"
                                type="text"
                                name="registrasiHIS"
                            />
                        </div>
                        <div
                            style="display: flex;justify-content: center;margin-bottom: 10px;margin-top: 10px;"
                            class="form-group row"
                        >
                            <label style="margin-right: 20px;">
                                MRN:
                            </label>

                            <input
                                autocomplete="false"
                                v-model="noMrn"
                                placeholder="MRN"
                                type="text"
                                name="MRN"
                            />
                        </div>

                        <button
                            @click.prevent="postRhis"
                            class="btn btn-brand btn-md btn-bold btn-upper"
                            style="padding: 0.25rem 1rem !important;"
                        >
                            SAVE
                        </button>
                    </form>
                    <div style="padding: 0 20px 0 20px;margin: 30px 0 0 0;">
                        <img
                            style="width:100%"
                            v-if="dataBooking.data.patientLab.imgKtp"
                            :src="
                                linkKtp +
                                    `/${
                                        dataBooking.data.patientLab.encryptedId
                                    }`
                            "
                            alt=""
                        />
                    </div>
                    <div
                        style="display: flex;flex-direction: column;align-items: center;font-size: large;font-weight: 700;"
                    >
                        <img
                            :src="isFotoPlat ? fotoPlat : qrCodeLink"
                            style="width: 100%;"
                            alt="'Loading Image'"
                            srcset=""
                        />
                        <input
                            type="hidden"
                            id="linkQrRegistrasi"
                            :value="dataBooking.data.linkQrRegistrasi"
                        />
                        Link Qr Registrasi :
                        {{ dataBooking.data.linkQrRegistrasi }}
                        <a
                            style="cursor: pointer;margin-left: 10px;"
                            @click.prevent="copyLinkQrRegistrasi"
                        >
                            <i class="far fa-copy"></i>
                        </a>
                    </div>
                    <div style="max-width: 285px;">
                        <h3
                            style="color: black;text-overflow: ellipsis;width: 250px;white-space: nowrap; overflow: hidden;"
                        >
                            {{
                                `${dataBooking.data.patientLab.namaDepan} ${
                                    dataBooking.data.patientLab.namaBelakang
                                        ? dataBooking.data.patientLab
                                              .namaBelakang
                                        : ""
                                }`
                            }}
                        </h3>
                        <h5 style="color: black;">
                            Tanggal Lahir :
                            {{ dataBooking.data.patientLab.tglLahir }}
                        </h5>
                        <h5 style="color: black;">
                            NIK : {{ dataBooking.data.patientLab.nik }}
                        </h5>
                        <h5 style="color: black;">
                            No.Telp :
                            {{ dataBooking.data.patientLab.noTelepon }}
                        </h5>
                        <h5 style="color: black;">
                            Email : {{ dataBooking.data.patientLab.email }}
                        </h5>
                        <h5 style="color: black;">
                            HIS :{{
                                dataBooking.data.noRegistrasiHIS
                                    ? dataBooking.data.noRegistrasiHIS
                                    : "-"
                            }}
                        </h5>
                        <h5 style="color: black;">
                            MRN :{{
                                dataBooking.data.noMrn
                                    ? dataBooking.data.noMrn
                                    : "-"
                            }}
                        </h5>
                        <label
                            class="btn btn-elevate btn-pill btn-sm mr-2 btn-outline-success active"
                        >
                            {{
                                calculatePrice(
                                    dataBooking.data.paketPemeriksaans
                                )
                            }}
                        </label>
                    </div>
                </div>

                <div class="modal-footer"></div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapMutations, mapState, mapActions } from "vuex";
import CheckPermission from "../../../services/checkPermission.service";
const checkPermission = new CheckPermission();
export default {
    name: "QrCodeModal",
    props: {
        modalName: { type: String, default: "driveThruModal" }
    },
    data() {
        return {
            noHis: null,
            linkKtp: process.env.VUE_APP_API_URL + "/api/pcr/ktp",
            noMrn: null
        };
    },
    created() {
        this.noHis = this.dataBooking.data.noRegistrasiHIS;
        this.noMrn = this.dataBooking.data.noMrn;
    },
    mounted() {
        $("#" + this.modalName).modal();
    },
    computed: {
        ...mapState("DriveThruStore", {
            isModalVisible: state => state.isModalVisible,
            qrCodeLink: state => state.qrCodeLink,
            isFotoPlat: state => state.isFotoPlat,
            fotoPlat: state => state.fotoPlat,
            dataBooking: state => state.dataBooking,
            dtBooking: state => state.dtBooking
        })
    },
    methods: {
        ...mapMutations("DriveThruStore", {
            setModalVisible: "SET_MODAL_VISIBLE",
            setQrCodeLink: "SET_QR_CODE_LINK"
        }),
        ...mapActions("DriveThruStore", { postNoHis: "postNoHis" }),
        close() {
            this.setQrCodeLink(null);
            $("#" + this.modalName).modal("hide");
            this.setModalVisible(false);
        },
        copyLinkQrRegistrasi() {
            let linkQrRegistrasiElement = document.querySelector(
                "#linkQrRegistrasi"
            );
            linkQrRegistrasiElement.setAttribute("type", "text");
            linkQrRegistrasiElement.select();
            try {
                document.execCommand("copy");
                toastr.success("Berhasil disalin");
            } catch (err) {
                toastr.error("Gagal disalin");
            }
            linkQrRegistrasiElement.setAttribute("type", "hidden");
            window.getSelection().removeAllRanges();
        },
        checkPermission: function(method) {
            return checkPermission.isCanAccess(method);
        },
        checkFlow(flow) {
            let flag = false;
            let flowArray = this.dtBooking.statusFlow;
            if (flow == "cancel") {
                flowArray.forEach(e => {
                    if (e.status == "CANCEL" || e.status == "COMPLETE") {
                        flag = true;
                    }
                });
            }

            return flag;
        },
        calculatePrice(array) {
            let price = 0;
            array.forEach(element => {
                if (!element.cancel) {
                    price += element.paketPemeriksaan.price;
                }
            });
            if (price > 0) {
                return price.toLocaleString("id", {
                    style: "currency",
                    currency: "IDR"
                });
            }
            return "0".toLocaleString("id", {
                style: "currency",
                currency: "IDR"
            });
        },
        postRhis() {
            const orderId = this.dtBooking.orderId;
            const testId = this.dataBooking.data.id;
            this.postNoHis({
                data: {
                    orderId: orderId,
                    mrn: this.noMrn,
                    testId: testId,
                    hisRefNumber: this.noHis,
                    tglKedatangan: moment().format("YYYY-MM-DD HH:mm:ss")
                },
                id: orderId
            });
            this.close();
        }
    }
};
</script>
