<template>
  <div>
    <input
      @click="showButtonExtra()"
      type="text"
      v-bind:id="idDatePicker"
      class="form-control input-datepicker"
      v-bind:placeholder="placeHolder"
      :value="selectedValue"
      autocomplete="off"
      v-validate="isValidate ? 'required' : ''"
      data-vv-validate-on="blur"
      data-vv-as="Tanggal"
      :data-vv-scope="(scope != null) ? scope : null"
      :name="name"
      :class="{'is-invalid': errors.has(name) }"
      :disabled="isDisabled"
    />
    <div
      v-show="errors.first(name)"
      class="invalid-feedback"
    >{{ errors.first(name) }}</div>
  </div>
</template>

<script>
export default {
  inject: ["$validator"],
  data() {
    return {
      dataResponse: null,
      dateValue: null,
      flag: false,
      name:'date'
    };
  },
  props: {
    idDatePicker: { type: String },
    pickerValue: {},
    placeHolder: { type: String },
    isValidate: { type: Boolean },
    btnToday: { type: Boolean, default:false },
    btnMax: { type: Boolean, default:false },
    btnMin: { type: Boolean, default:false },
    startDate: { type: String, default: '' },
    endDate: { type: String, default: '' },
    daysDisabled: { type: String },
    scope: {type: String, default: null},
    isDisabled: { type: Boolean, default: false},
  },
  computed: {
    selectedValue() {
      if (this.pickerValue) {
        var dateSelected = moment(this.pickerValue);
        return dateSelected.format("DD/MM/YYYY");
      }
    }
  },
  methods: {
    showButtonExtra: function() {
        var vx = this;
        if(!this.flag && this.btnMax) {
            $(".datepicker table.table-condensed tfoot").append('<tr><th colspan="7" class="max_datepicker" style="font-weight: 500;">Max Date</th></tr>');
            $(".datepicker table.table-condensed tfoot .max_datepicker").on('click',function(){
                $("#" + vx.idDatePicker).datepicker("update", new Date('9999-12-31'));
            });
        }
        if(!this.flag && this.btnMin) {
            $(".datepicker table.table-condensed tfoot").append('<tr><th colspan="7" class="min_datepicker" style="font-weight: 500;">Min Date</th></tr>');
            $(".datepicker table.table-condensed tfoot .min_datepicker").on('click',function(){
                $("#" + vx.idDatePicker).datepicker("update", new Date('1900-01-01'));
            });
        }
        vx.flag = true;
    },
    getDatePicker: function () {
      $.fn.datepicker.dates["id"] = {
        days: ["Minggu", "Senin", "Selasa", "Rabu", "Kamis", "Jumat", "Sabtu"],
        daysShort: ["Min", "Sen", "Sel", "Rab", "Kam", "Jum", "Sab"],
        daysMin: ["Mg", "Sn", "Sl", "Rb", "Km", "Jm", "Sb"],
        months: [
          "Januari",
          "Februari",
          "Maret",
          "April",
          "Mei",
          "Juni",
          "Juli",
          "Agustus",
          "September",
          "Oktober",
          "November",
          "Desember"
        ],
        monthsShort: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "Mei",
          "Jun",
          "Jul",
          "Agt",
          "Sep",
          "Okt",
          "Nov",
          "Des"
        ],
        weekStart: 1
      };


      $("#" + this.idDatePicker)
        .datepicker({
          language: "id",
          todayHighlight: true,
          format: "dd/mm/yyyy",
          startDate: this.startDate,
          endDate: this.endDate,
          todayBtn: this.btnToday ? "linked" : false,
          daysOfWeekDisabled: this.daysDisabled
        })
        .on("change", () => {
          this.dateValue = $("#" + this.idDatePicker).val();
          if (this.dateValue.length == 10) {
            var dateSelected = moment(this.dateValue, "DD/MM/YYYY");
            this.$emit("update:changedValue", dateSelected.format("YYYY-MM-DD"));
          }
          else if (this.dateValue.length == 0) {
            this.$emit("update:changedValue", null);
          }
        });
    }
  },
  mounted() {
      this.getDatePicker();
       if(this.scope != null){
        this.name = this.scope+'.'+this.name;
      }
  },
};
</script>

